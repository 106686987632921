<template>
  <div class="registerSuccess">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback()" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="registerSuccess_container">
      <img class="success-img" src="/subject/registerSuccess/chenggong.png" alt="">
      <div class="registerSuccess_container-title">
        恭喜你, 开户成功
      </div>
      <div class="registerSuccess_container-box">
        <div class="registerSuccess_container-box-card">
          <span>
            恭喜您获得<span class="red">588</span> 美元赠金
          </span>
        </div>
      </div>
    </div>
    <div class="registerSuccess_container">
      <div class="registerSuccess_container-title">
        您已开通MT5真实账户！
      </div>
      <div class="registerSuccess_container-account">
        <div class="registerSuccess_container-account-title">
          真实账户
        </div>
        <div class="registerSuccess_container-account-info">
          <span class="tradeAccount">交易账号：{{account || '------'}}</span>
          <span class="tradePassword">交易密码：{{password || '------'}}</span>
        </div>
      </div>
      <div class="registerSuccess_container-downloadBtn" @click="handleDownload()">下载红狮APP</div>
      <div class="registerSuccess_container-download registerSuccess_container-title">
        <img src="/subject/registerSuccess/arrowLeft.png" />
        <span>&nbsp;下载APP，真实交易享额外优惠&nbsp;</span>
        <img src="/subject/registerSuccess/arrowRight.png" />
      </div>
      <div class="registerSuccess_container-level">
        <div class="left-part">
          <img src="/subject/registerSuccess/mini.png" />
          <span>迷你账户</span>
        </div>
        <div class="right-part">
          <span class="deposit-require">入金200美元</span>
          <span class="point-discount">
            点差优惠<span class="red">15美元/手</span>
          </span>
        </div>
      </div>
      <div class="registerSuccess_container-level">
        <div class="left-part">
          <img src="/subject/registerSuccess/standard.png" />
          <span>标准账户</span>
        </div>
        <div class="right-part">
          <span class="deposit-require">入金1000美元</span>
          <span class="point-discount">
            点差优惠<span class="red">20美元/手</span>
          </span>
        </div>
      </div>
      <div class="registerSuccess_container-level">
        <div class="left-part">
          <img src="/subject/registerSuccess/highend.png" />
          <span>高端账户</span>
        </div>
        <div class="right-part">
          <span class="deposit-require">入金10000美元</span>
          <span class="point-discount">
            点差优惠<span class="red">25美元/手</span>
          </span>
        </div>
      </div>
    </div>
    <contactusVue></contactusVue>
  </div>  
</template>

<script>
import ContactusVue from '../../Contactus.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    ContactusVue,
  },
  data() {
    return {
      navBarFixed: false,
      account: '',
      password: '',
    }
  },
  created() {
    this.$emit('controlShow', true);
    const _d = this.$route.query._d || '';
    if(_d) {
      const accountData = JSON.parse(atob(_d)) || {};
      this.account = accountData.a;
      this.password = accountData.b;
    }
  },
  methods: {
    ...mapActions('common', {
      handleDownload: 'handleDownload',
    }),
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem('craig');
    },
  },
}
</script>

<style lang="scss" scoped>
.registerSuccess {
  color: #333;
  background: #f5f5f9;

  .tabbar {
    background-color: #ffffff;
    padding: 10px 30px;

    img {
      width: 130px;
      cursor: pointer;
    }
  }

  .navBarFixed {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  &_container {
    background: #fff;
    border-top: 16px solid #f5f5f9;
    padding: 16px;
    text-align: center;

    .red {
      color: red;
    }

    .success-img {
      width: 90px;
      height: 90px;
    }
    &-title {
      margin: 16px 8px;
      font-size: 25px;
      font-weight: bold;
    }
    &-box {
      border: red 1px solid;
      margin: auto;
      display: flex;
      max-width: 764px;
      align-items: center;
      border-radius: 20px;
      justify-content: center;

      &-card {
        width: 260px;
        height: 290px;
        margin: 16px;
        position: relative;
        background-size: contain;
        background-image: url('/subject/registerSuccess/cardBackground.png');
        background-repeat: no-repeat;
        background-position: center;

        >span {
          top: 80px;
          left: 15%;
          color: #833D05;
          width: 70%;
          position: absolute;
          font-size: 16px;
          text-align: center;
          word-break: break-all;
          font-weight: bold;
          white-space: normal;
        }
      }
    }
    &-account {
      color: white;
      width: 600px;
      height: 200px;
      margin: 32px auto;
      position: relative;
      font-size: 16px;
      background-size: 577px 197px;
      background-image: url('/subject/registerSuccess/accountBackground.png');
      background-repeat: no-repeat;
      background-position: center;

      &-title {
        top: 10%;
        left: 15%;
        position: absolute;
        font-size: 32px;
      }
      .tradeAccount {
        top: 44%;
        left: 22%;
        position: absolute;
        font-size: 20px;
      }
      .tradePassword {
        top: 70%;
        left: 22%;
        position: absolute;
        font-size: 20px;
      }
    }
    &-downloadBtn {
      color: white;
      padding: 8px;
      font-size: 20px;
      background: red;
      border-radius: 36px;
      width: 262px;
      font-family: Microsoft YaHei, Arial, Helvetica, sans-serif;
      font-weight: 500;
      line-height: 1.75;
      margin: auto;
      cursor: pointer;
    }
    &-download {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-level {
      width: 447px;
      height: 83px;
      margin: 16px auto;
      position: relative;
      background-size: contain;
      background-image: url('/subject/registerSuccess/levelBackground.png');
      background-repeat: no-repeat;
      background-position: center;

      .left-part {
        img {
          top: 11px;
          left: 38px;
          width: 41px;
          height: 35px;
          position: absolute;
        }
        span {
          left: 30px;
          bottom: 11px;
          position: absolute;
          font-size: 16px;
        }
      }
      .right-part {
        .deposit-require {
          top: 12px;
          left: 140px;
          position: absolute;
          font-size: 20px;
        }
        .point-discount {
          top: 44px;
          left: 140px;
          position: absolute;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }
}
@media (max-width: 959.95px)  {
  .registerSuccess {
    color: #333;
    .tabbar {
      padding: 10px 20px;

      img {
        width: 95px;
      }
    }

    .navBarFixed {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 999;
    }
    &_container {
      .success-img {
        width: 45px;
        height: 45px;
      }
      &-title {
        font-size: 21px;
      }
      &-box {
        border: none;
      }
      &-account {
        width: 375px;
        height: 120px;
        overflow: hidden;
        background-size: 375px 120px;

        &-title {
          font-size: 20px;
        }
        .tradeAccount {
          font-size: 14px;
        }
        .tradePassword {
          font-size: 14px;
        }
      }
      &-downloadBtn {
        font-size: 14px;
        width: 226px;
      }
      &-download {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-level {
        width: 305px;
        height: 57px;

        .left-part {
          img {
            top: 7px;
            left: 26px;
            width: 28px;
            height: 24px;
          }
          span {
            left: 18px;
            bottom: 7px;
            font-size: 12px;
          }
        }
        .right-part {
          .deposit-require {
            top: 10px;
            left: 96px;
            font-size: 14px;
          }
          .point-discount {
            top: 30px;
            left: 96px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>